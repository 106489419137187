<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >资源管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/annotation' }">批注积累</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
			<div class="padding-b-sm">
				<el-form :model="form"  ref="form1">
					<el-form-item label="批注标题" prop="title" :rules="[{ required: true, message: '请输入批注标题' , trigger: 'blur'}]">
						<el-input style="width: 18.75rem;" v-model="form.title" placeholder="批注标题"></el-input>
						<el-button class="margin-l-sm" size="mini" type="primary" @click="addTab" >插入批注页</el-button>
						<el-button class="margin-l-sm" size="mini" type="danger" @click="removeTab" >移除批注页</el-button>
					</el-form-item>
				</el-form>
			</div>
            <div>
                <el-tabs  v-model="currentTab" type="card"  @tab-click="selectTab">
                    <el-tab-pane
                            v-for="item in editableTabs"
                            :key="item.name"
                            :label="item.title"
                            :name="item.name"
                    >
                    <div>
                        <el-form :model="form.pages[currentTab]" label-width="100px" ref="form" class="demo-ruleForm" v-for="(item,itemIndex) in form.pages[currentTab]" :key="itemIndex">
							<div v-for="(subItem,index) in item" :key="index">
								<el-form-item label="类型：" prop="type">
									<el-select size="mini" v-model="subItem.type" placeholder="请选择">
										<el-option
												v-for="item in type"
												:key="item.value"
												:label="item.label"
												:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item v-if="subItem.type === 1" label="内容：">
									<el-input v-model="subItem.content" type="textarea"
											  :rows="10"
											  placeholder="请输入内容"></el-input>
								</el-form-item>
								<el-form-item v-else>
										<editCover :uploadPath="uploadPath" @success="onSuccess($event,subItem.id)" :pic="path+subItem.content"></editCover>
								</el-form-item>
							</div>
                        </el-form>

                    </div>
                    </el-tab-pane>
                </el-tabs>

                <el-button class="margin-l-sm" size="mini" type="primary" @click="validateForm()" >保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex"
	import editCover from '../../../components/editCover'
	import { config } from '../../../js/config'
    export default {
        name: "edit",
		components: { editCover },
        data() {
            return {
				uploadPath: 'annotation',
				path: config.imageUrl,
                currentTab:'0',
				type: [
					{
						value: 1,
						label: '内容'
					},
					{
						value: 2,
						label: '插图'
					}
				],
                editableTabs: [],
                form:{
					id: 0,
					title: "",
					pages: []
                },
            }
        },
		created() {
			this.form.id = this.$route.params.id
			this.getEditData()
		},
        methods: {
			...mapActions('annotation',['addAnnotation','getAnnotationDetail']),
            //添加选项
            removeOption(index){
                this.form.pages[this.currentTab].splice(index, 1)
            },
			// 表单提交前
			validateForm(){
				this.$refs['form1'].validate(async valid => {
					if (!valid) {
						return 
					} 
					this.submit()
				});
			},
            //提交表单保存
            async submit(){
               const {res_info, data } = await this.addAnnotation(this.form)
			   if(res_info !== 'ok') {
				   this.$message.error('保存失败!')
				   return
			   }
			   this.$message.success('保存成功!')
			   this.$router.push('/annotation')
            },
            selectTab(tab,event){
				this.currentTab = tab.index
            },
            //添加题目
            addTab() {
				let index = this.editableTabs.length
                let newTabName = index
                this.editableTabs.push({
                    title: '批注页'+(newTabName + 1),
                    name: newTabName + '',
                });
                this.form.pages.push({
					item: [
						{
							content: "",
							type: 1
						},
						{
							content: "",
							type: 1
						}
					]
				});
                this.currentTab = newTabName + '';
				this.currentIndex = Number(this.currentTab)
            },
            //删除批注页
            removeTab() {
                if(this.editableTabs.length === 1)
                {
                    this.$message.error('至少保存一页标注')
                    return
                }
                let tabs = this.editableTabs;
                let activeName = (this.editableTabs.length - 1) + '';
				var vm = this
				vm.form.pages.splice(activeName, 1)
				vm.currentTab = '0'
				vm.editableTabs = tabs.filter(tab => tab.name !== activeName);
                
            },
			// 图片上传
			onSuccess(path,id) {
				var that = this
				let content = that.form.pages[this.currentTab].item
				content.forEach(function(item){
					if(item.type === 2){
						if(item.id === id){
							item.content = path
						}
					}
				});
			},
			// 图片移除
			onRemove(index,file){
				this.form.pages[this.currentTab].splice(index,1)
			},
			// 获得数据
			async getEditData(){
				var vm = this
				const { res_info, data } = await this.getAnnotationDetail({id: this.form.id})
				if(res_info !== 'ok') return 
				vm.form.title = data.title
				data.pages.forEach(function(item,index){
					let newTabName = index;
					vm.editableTabs.push({
					    title: '批注页'+(newTabName + 1),
					    name: newTabName + '',
					});
					vm.currentTab = newTabName + ''
					vm.form.pages.push({
						item: item['contents']
					})
				})
			}
        }
    }
</script>
